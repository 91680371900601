import { onMounted, Ref } from 'vue';
import { IDialogComponent, useDialog } from '@/hooks/useDialog';
import { useUnsubs } from '@/hooks/useUnsubs';
import { SignalType, useSignal } from '@/hooks/useSignal';
import {
  apiCommands as reportingApiCommands,
  createTile,
  updateTile,
} from '@/service/api/reporting/api';
import { useProtectedDefaultCompany } from '@/hooks/useProtectedDefaultCompany';
import {
  ReportingDashboard,
  ReportingDashboardFull,
  ReportingDashboardMode,
} from '@/service/api/reporting/dasboard';
import * as GridConfig from '@/shared/grid';
import { Tile, UUID } from '@/service/api/reporting/tile';
import { ApiCommand as ReportingApiCommand } from '@/service/api/reporting/apiCommand';
import { IToastLevel, useToast } from '@/hooks/useToast';

export const useChartDialog = (
  activeGroup: Ref<ReportingDashboardFull>,
  activeGroupId: Ref<string>,
  activeGroupData: Ref<ReportingDashboard>,
  groups: Ref<ReportingDashboard[]>,
  onTileUpdate: (tile: Tile) => void,
  refetchingGroupPromise: Ref<Promise<void>>,
) => {
  const { showDialog } = useDialog();
  const { sub, unsub } = useUnsubs();
  const { showToast } = useToast();
  const { subscribeToSignal, dispatchSignal } = useSignal();
  const {
    companyId,
  } = useProtectedDefaultCompany();

  const findTile = (id: string) => activeGroup.value.tiles.find((t) => t.id === id);

  const openChartDialog = async ({
    model,
    tileId,
    tileGridConfig,
    dashboard,
  }: { model: Tile; tileId: UUID|undefined; tileGridConfig?: GridConfig.TileGridConfig; dashboard: ReportingDashboard }) => {
    const closeDialog = await showDialog({
      component: IDialogComponent.reportingChartDialog,
      preventCloseOnRouteChange: true,
      addInRoute: false,
      payload: {
        model,
        tileId,
        tileGridConfig,
        dashboard,
      },
    });
    sub(closeDialog);
  };
  onMounted(async () => {
    const unsub = subscribeToSignal(
      SignalType.reportingChartDialogModelUpdate,
      async ({
        model, tileId, tileGridConfig,
      }: {
        model: Tile;
        tileId: UUID|undefined;
        tileGridConfig?: GridConfig.TileGridConfig;
      }) => {
        let status;
        let response;

        const activeGroupIsDefault = !activeGroupData.value.user && !activeGroupData.value.company;
        if (activeGroupIsDefault) {
          const closeToast = await showToast({
            level: IToastLevel.info,
            message: 'pure',
            params: {
              message: 'Копирование дашборда..',
            },
          });
          const response = await reportingApiCommands[
            ReportingApiCommand.dashboardCopy
          ].request({
            company_id: companyId.value,
            dashboard_id: activeGroupData.value.id,
            name: activeGroupData.value.name,
            save_parent_id: true,
            mode: ReportingDashboardMode.user_and_company,
          });
          closeToast();
          if (!response.status) {
            return;
          }
          // @ts-ignore
          await activeGroupId._setter(response.response.id);
          await refetchingGroupPromise.value;
          groups.value.push(response.response as ReportingDashboard);
        }

        if (tileId) {
          const currentTile: Tile|undefined = activeGroupIsDefault
            ? activeGroup.value.tiles.find(
              (tile) => JSON.stringify(tile.config) === JSON.stringify(tileGridConfig),
            )
            : model;
          if (!currentTile) {
            return;
          }
          const resp = await updateTile({
            company_id: companyId.value,
            dashboard_id: activeGroupId.value,
            tile_id: activeGroupIsDefault ? currentTile.id : tileId,
            ...model,
          });
          status = resp.status;
          response = resp.response;
          if (status && response !== null) {
            const currentTileIndex = activeGroup.value.tiles.findIndex((t) => t.id === tileId);
            if (currentTileIndex !== -1) {
              activeGroup.value.tiles.splice(
                currentTileIndex, 1, resp.response as Tile,
              );
            }
            onTileUpdate(response);
          }
        } else {
          // @ts-ignore
          const resp = await createTile({
            company_id: companyId.value,
            dashboard_id: activeGroupId.value,
            ...model,
            config: tileGridConfig as unknown as GridConfig.TileGridConfig,
          });
          status = resp.status;
          response = resp.response;
          if (status) {
            // @ts-ignore
            activeGroup.value.tiles.push(response);
          }
          onTileUpdate(response);
        }
        dispatchSignal(
          SignalType.reportingChartDialogModelResponse,
          { status, response },
        );
      },
    );
    sub(unsub);
  });

  return {
    openChartDialog,
  };
};
