// @ts-nocheck grid
/* eslint-disable */

const coordIsInBox = (clientX, clientY, {
  left, right, top, bottom,
}) => (
  clientX >= left && clientX <= right && clientY >= top && clientY <= bottom
);

export const getElementHighlightedGridParams = (el, clientX, clientY) => {
  const box = el.getBoundingClientRect();
  if (!box) {
    console.error('el no box', el);
    return;
  }
  const {
    colStart, colEnd, rowStart, rowEnd,
  } = getCellPlacementFromDataset(el);
  const rowSpan = rowEnd - rowStart;
  const colSpan = colEnd - colStart;
  const {
    left, right, top, bottom,
  } = box;
  const partWidth = (right - left) / colSpan;
  const partHeight = (bottom - top) / rowSpan;
  // координаты частей змейкой слева направо сверху вниз
  const getRowNumber = (n) => Math.ceil(n / colSpan);
  const getColNumber = (n) => {
    const mod = n % colSpan;
    return mod === 0 ? colSpan : mod;
  };
  const partCoords = new Array(rowSpan * colSpan).fill(null).map((_, i) => {
    const n = i + 1;
    const rowNumber = getRowNumber(n);
    const colNumber = getColNumber(n);
    let _left; let _right; let _top; let
      _bottom;
    _left = left + partWidth * (colNumber - 1);
    _right = _left + partWidth;
    _top = top + partHeight * (rowNumber - 1);
    _bottom = _top + partHeight;
    const coordBox = {
      left: _left,
      right: _right,
      top: _top,
      bottom: _bottom,
    };
    const gridPosition = {
      col: colStart + colNumber - 1,
      row: rowStart + rowNumber - 1,
    };
    return {
      coordBox, gridPosition, colNumber, rowNumber,
    };
  });
  const currentPart = partCoords.find(({ coordBox }) => coordIsInBox(clientX, clientY, coordBox));
  return currentPart ? currentPart.gridPosition : null;
};

export const getCellPlacementFromDataset = (el) => {
  const { dataset } = el;
  if (!dataset) return;
  const {
    colStart, colEnd, rowStart, rowEnd,
  } = dataset;
  return {
    colStart: +colStart,
    colEnd: +colEnd,
    rowStart: +rowStart,
    rowEnd: +rowEnd,
  };
};

export const getPlaceholderPosition = (el) => {
  const pos = getCellPlacementFromDataset(el);
  if (!pos) return null;
  return {
    col: pos.colStart,
    row: pos.rowStart,
  };
};
