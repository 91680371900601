// @ts-nocheck grid
/* eslint-disable */

export const getPlaceholderStyleByPosition = (rowIndex, colIndex) => ({
  'grid-column-start': colIndex + 1,
  'grid-column-end': colIndex + 2,
  'grid-row-start': rowIndex + 1,
  'grid-row-end': rowIndex + 2,
});

const findById = (elements, id) => elements.find((el) => el[2] === id);

// сдвигаем промежуточные элементы назад
const elementsAreOnTheSameRow = (el1, el2) => {
  const [start1, end1] = el1[1];
  const [start2, end2] = el2[1];
  return start1 === start2 && end1 === end2;
};

const shiftBackOnTheRow = (
  elements,
  currentElementIndex,
  targetElementIndex,
) => {
  console.log('shiftBackOnTheRow');
  const currentElement = elements[currentElementIndex];
  const targetElement = elements[targetElementIndex];
  const elementWidth = currentElement[0][1] - currentElement[0][0];
  const rightBorder = targetElement[0][1];
  const leftBorder = currentElement[0][0];
  elements.forEach((el, i) => {
    // элементы одного ряда
    if (elementsAreOnTheSameRow(el, currentElement)) {
      if (i !== currentElementIndex && el[0][1] <= rightBorder && el[0][1] > leftBorder) {
        el[0][0] = el[0][0] - elementWidth;
        el[0][1] = el[0][1] - elementWidth;
      }
    }
  });
  const targetElementRightBorder = targetElement[0][1];
  currentElement[0][0] = targetElementRightBorder;
  currentElement[0][1] = targetElementRightBorder + elementWidth;
};

// сдвигаем промежуточные элементы вперед
const shiftForwardOnTheRow = (
  elements,
  currentElementIndex,
  targetElementIndex,
) => {
  console.log('shiftForwardOnTheRow');
  const currentElement = elements[currentElementIndex];
  const targetElement = elements[targetElementIndex];
  const elementWidth = currentElement[0][1] - currentElement[0][0];
  const leftBorder = targetElement[0][0];
  const rightBorder = currentElement[0][1];
  elements.forEach((el, i) => {
    // элементы одного ряда
    if (elementsAreOnTheSameRow(el, currentElement)) {
      console.log('shiftForwardOnTheRow', el[0][0], leftBorder);
      if (i !== currentElementIndex && el[0][0] >= leftBorder && el[0][1] <= rightBorder) {
        el[0][0] = el[0][0] + elementWidth;
        el[0][1] = el[0][1] + elementWidth;
      }
    }
  });
  const targetElementLeftBorder = targetElement[0][0];
  console.log('targetElementLeftBorder', targetElementLeftBorder);
  currentElement[0][1] = targetElementLeftBorder;
  currentElement[0][0] = targetElementLeftBorder - elementWidth;
};

export const recalculateElementPositions = (
  elementPositions,
  currentElementIndex,
  targetElementIndex,
) => {
  {
    const copyElements = JSON.parse(JSON.stringify(elementPositions));
    const currPos = copyElements[currentElementIndex];
    const targetPos = copyElements[targetElementIndex];
    console.log('recalculateElementPositions',
      JSON.stringify(copyElements),
      JSON.stringify(currPos),
      JSON.stringify(targetPos));
  }
  const currPosId = elementPositions[currentElementIndex][2];
  const targetPosId = elementPositions[targetElementIndex][2];
  const copyElements = JSON.parse(JSON.stringify(elementPositions))
    .sort((a, b) => ((a[0][0] - b[0][0] > 0) && (a[1][0] - b[1][0] > 0) ? 1 : -1));
  const currPos = findById(copyElements, currPosId);
  const targetPos = findById(copyElements, targetPosId);
  console.log('currPos', currPos, 'targetPos', targetPos);
  if (elementsAreOnTheSameRow(currPos, targetPos)) {
    const isMovingForward = currPos[0][1] < targetPos[0][1];
    if (isMovingForward) {
      shiftBackOnTheRow(
        copyElements,
        currentElementIndex,
        targetElementIndex,
      );
    } else {
      shiftForwardOnTheRow(
        copyElements,
        currentElementIndex,
        targetElementIndex,
      );
    }
  }

  return elementPositions.map(([_, __, id]) => copyElements.find((el) => {
    const copyId = el[2];
    return id === copyId;
  }));
};
