import {
  computed,
  onBeforeUnmount, Ref, ref, watch,
} from 'vue';
import { useProtectedDefaultCompany } from '@/hooks/useProtectedDefaultCompany';
import { useSimpleFetch } from '@/hooks/useSimpleFetch';
import { apiCommands as reportingApiCommands } from '@/service/api/reporting/api';
import {
  TileData, TileDataResponse, Tile, UUID,
} from '@/service/api/reporting/tile';
import { ApiCommand, ApiCommand as ReportingApiCommand } from '@/service/api/reporting/apiCommand';
import {
  ReportingDashboard,
  ReportingDashboardFull,
} from '@/service/api/reporting/dasboard';
import { SignalType, useSignal } from '@/hooks/useSignal';
import { useQueries } from 'vue-query';

const dashboardIsDefault = (d: ReportingDashboard) => !d.company && !d.user;

export const useGroups = (activeGroupId: Ref<string|undefined>) => {
  const { dispatchSignal } = useSignal();
  const {
    companyId,
  } = useProtectedDefaultCompany();
  const fetchGroups = () => reportingApiCommands[ReportingApiCommand.dashboardGetList].request({
    company_id: companyId.value,
  });
  const groupsStorage = useSimpleFetch(fetchGroups, []);
  const groupsIsLoaded = computed(
    () => groupsStorage.data.value,
  );
  // const activeGroupId = ref<UUID>();
  const fetchGroupData = () => reportingApiCommands[ReportingApiCommand.dashboardGetFull].request({
    company_id: companyId.value,
    dashboard_id: activeGroupId.value as UUID,
  });
  const activeGroup = ref<ReportingDashboardFull|null>(null);
  const dataIsLoaded = ref(false);
  const groupIsLoading = ref(true);
  const loadingGroupPromise = ref<Promise<void>|undefined>();
  const refetchDataTimerId = ref<number|null>(null);

  const groupsComputed = computed(
    () => {
      const groups = groupsStorage.data.value;
      if (!groups) return [];
      const noParentGroups = groups.filter((gr) => !dashboardIsDefault(gr) && !gr.parent_id);
      const defaultGroups = groups.filter((gr) => dashboardIsDefault(gr));
      const defaultGroupsPrepared = defaultGroups.map((gr) => {
        const userGroup = groups.find((group) => group.parent_id === gr.id);
        if (userGroup) {
          return {
            ...userGroup,
            isCustomDefault: true,
            name: userGroup.name || gr.name,
          };
        }
        return {
          ...gr,
          isDefault: true,
        };
      });
      return [
        ...noParentGroups,
        ...defaultGroupsPrepared,
      ];
    },
  );

  const activeGroupData = computed(
    () => groupsComputed.value.find((gr) => gr.id === activeGroupId.value),
  );

  const groupsDefault = computed(
    () => (groupsStorage.data.value || []).filter(dashboardIsDefault),
  );

  const activeGroupIsDefault = computed(
    () => activeGroup.value && dashboardIsDefault(activeGroup.value),
  );

  const activeGroupIsCustomDefault = computed(
    () => {
      const id = activeGroupId.value;
      if (!id) return false;
      const group = groupsComputed.value.find((gr) => gr.id === id);
      if (!group) return false;
      return !!group.isCustomDefault;
    },
  );

  onBeforeUnmount(() => {
    if (refetchDataTimerId.value) {
      clearInterval(refetchDataTimerId.value);
    }
  });
  const MINUTES_15 = 1000 * 60 * 15;

  const tileIds = ref<string[]>([]);
  const tilesQueriesOptions = computed(() => tileIds.value.map((id) => {
    return {
      queryKey: ['tile', companyId.value, id],
      queryFn: () => reportingApiCommands[ReportingApiCommand.tileGetData].request({
        company_id: companyId.value,
        dashboard_id: activeGroupId.value as UUID,
        tile_id: id,
      }),
      staleTime: MINUTES_15,
      refetchInterval: MINUTES_15,
      cacheTime: MINUTES_15,
    };
  }));
  const tilesQueries = useQueries(tilesQueriesOptions);
  watch(tilesQueries, (results) => {
    if (!activeGroup.value) {
      return;
    }
    activeGroup.value = {
      ...activeGroup.value,
      tiles: activeGroup.value.tiles.map((tile, i) => ({
        ...tile,
        data: results[i]?.data?.response,
      })),
    };
  });

  const fetch = async () => {
    dataIsLoaded.value = false;
    const response = await reportingApiCommands[ReportingApiCommand.dashboardGet].request({
      company_id: companyId.value,
      dashboard_id: activeGroupId.value as UUID,
    });
    if (response.status) {
      if ((response.response as ReportingDashboardFull).id !== activeGroupId.value) {
        return;
      }
      const tiles = (response.response as ReportingDashboardFull).tiles;
      dataIsLoaded.value = true;
      activeGroup.value = response.response;
      tileIds.value = tiles.map((tile) => tile.id);
      dispatchSignal(SignalType.dashboardLoaded);
    }
  };
  groupsStorage.fetch();

  const deleteGroup = async (id: string) => {
    const response = await reportingApiCommands[ReportingApiCommand.dashboardDelete].request({
      company_id: companyId.value,
      dashboard_id: id,
    });
    if (response.status) {
      const i = groupsStorage.data.value?.findIndex(
        (group) => group.id === id,
      ) ?? -1;
      if (i !== -1) {
        groupsStorage.data.value?.splice(i, 1);
      }
      if (activeGroupId.value === id) {
        if (!groupsStorage.data.value?.length) {
          activeGroupId.value = undefined;
        } else {
          activeGroupId.value = groupsStorage.data.value[0].id;
        }
      }
    }
  };

  watch(companyId, () => {
    groupsStorage.data.value = [];
    activeGroupId.value = undefined;
  });
  watch(activeGroupId, (newValue, oldValue) => {
    activeGroup.value = null;
  });

  watch(groupsIsLoaded, (isLoaded) => {
    if (isLoaded) {
      if (!activeGroupId.value) {
        const groups = groupsComputed.value;
        if (groups.length) {
          activeGroupId.value = groups[0].id;
          // const defaultDashboard = n.find(dashboardIsDefault);
          // if (defaultDashboard) {
          //   const userDashboard = n.find((d) => d.parent_id === defaultDashboard.id);
          //   console.log('userDashboard', defaultDashboard, userDashboard);
          //   activeGroupId.value = userDashboard ? userDashboard.id : defaultDashboard.id;
          // } else {
          //   activeGroupId.value = n[0]?.id ?? null;
          // }
        }
      }
    }
  });

  watch(activeGroupId, async (id) => {
    if (id) {
      let resolver: () => void;
      loadingGroupPromise.value = new Promise((resolve) => {
        resolver = resolve;
      });
      groupIsLoading.value = true;
      await fetch();
      groupIsLoading.value = false;
      // @ts-ignore
      resolver();
    }
  }, { immediate: true });

  return {
    groups: groupsStorage.data,
    groupsComputed,
    activeGroup,
    refetchActiveGroup: fetch,
    refetchGroups: groupsStorage.fetch,
    groupIsLoading,
    groupIsLoaded: dataIsLoaded,
    groupsDefault,
    activeGroupData,
    activeGroupIsDefault,
    activeGroupIsCustomDefault,
    loadingGroupPromise,
    deleteGroup,
  };
};
