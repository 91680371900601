// @ts-nocheck grid
/* eslint-disable */

const drawGrid = (grid) => {
  let idLength = '65e5d2c9-bec4-48d7-bfb1-d5a5ed6e23f6'.length;
  let empty = ' '.repeat(idLength)
  let rowLength;
  const drawnGrid = grid.reduce((acc, row) => {
    const drawnRow = `${row.reduce((rowAcc, el) => {
      rowAcc = `${rowAcc}| ${el ? el.id : empty} `;
      return rowAcc;
    }, '')}|\n`;
    rowLength = drawnRow.length;
    const drawnRowDivider = `${new Array(drawnRow.length - 1).fill('-').join('')}\n`;
    acc = acc + drawnRow + drawnRowDivider;
    return acc;
  }, '');
  return `${new Array(rowLength - 1).fill('-').join('')}\n${drawnGrid}`;
};

const consoleGridWithMessage = (grid, msg) => {
  if (msg) {
    console.log(msg);
  }
  console.log(drawGrid(grid));
};

const RIGHT = 'RIGHT';
const LEFT = 'LEFT';

// слева направо
const findFirstUniqIdsInArea = (
  grid,
  [rowStart, rowEnd],
  [colStart, colEnd],
) => {
  const uniqIdSet = new Set();

  for (let rowIndex = rowStart; rowIndex <= rowEnd; rowIndex++) {
    let currentRowFirstElement;
    for (
      let colIndex = colStart;
      colIndex <= colEnd && !currentRowFirstElement;
      colIndex++
    ) {
      const el = grid[rowIndex][colIndex];
      if (el) currentRowFirstElement = el;
    }
    if (currentRowFirstElement) {
      uniqIdSet.add(currentRowFirstElement.id);
    }
  }
  return Array.from(uniqIdSet);
};

const joinGrids = (grid1, grid2) => {
  const copy1 = JSON.parse(JSON.stringify(grid1));
  const copy2 = JSON.parse(JSON.stringify(grid2));
  return copy1.map((row, i) => [...row, ...copy2[i]]);
};

const getRightEdgeElementCells = (id, grid) => {
  const coords = findElementCellsById(id, grid);
  const maxCol = Math.max(...coords.map(([_, col]) => col));
  return coords.filter(([_, col]) => col === maxCol);
};

const findElementsCellsByIdSplittedByRows = (idArray, grid) => {
  const found = [];
  grid.forEach((row, rowIndex) => {
    found.push([]);
    row.forEach((col, colIndex) => {
      if (col && idArray.includes(col.id)) {
        found[rowIndex].push([rowIndex, colIndex]);
      }
    });
  });
  return found;
};

const findElementCellsById = (id, grid) => {
  const found = [];
  grid.forEach((row, rowIndex) => {
    row.forEach((col, colIndex) => {
      if (col && col.id === id) {
        found.push([rowIndex, colIndex]);
      }
    });
  });
  return found;
};

const splitGrid = (grid, colIndexBeforeSplit) => {
  const copy = JSON.parse(JSON.stringify(grid));
  const left = [];
  const right = [];
  copy.forEach((row) => {
    const rightRow = row.slice(colIndexBeforeSplit + 1);
    const leftRow = row.slice(0, colIndexBeforeSplit + 1);
    left.push(leftRow);
    right.push(rightRow);
  });
  return { left, right };
};

// всегда вычислять startRowIndex, даже если юзер вставил в другое место
const isEnoughSpaceToInsertEl = (
  grid,
  startRowIndex,
  colIndexAfterOrBefore,
  elRowSpan,
  elColSpan,
  shiftDirection = RIGHT,
) => {
  const getChangedColIndex = shiftDirection === RIGHT ? (i) => i + 1 : (i) => i - 1;
  const startColIndex = shiftDirection === RIGHT
    ? colIndexAfterOrBefore + 1
    : colIndexAfterOrBefore - 1;
  const rowLength = grid[0].length;
  let isEnoughSpace = true;
  let currentRowIndex = startRowIndex;
  let currentColIndex = startColIndex;
  const breakColIndex = startColIndex + elColSpan - 1;
  const breakRowIndex = startRowIndex + elRowSpan - 1;

  while (isEnoughSpace) {
    const cellValue = grid[currentRowIndex][currentColIndex];
    if (cellValue) {
      isEnoughSpace = false;
    } else if (currentColIndex === breakColIndex) {
      if (currentRowIndex === breakRowIndex) {
        // прошли все строки и ячейки, куда нужно вставить - места хватает
        if (breakColIndex >= rowLength) {
          isEnoughSpace = false;
        }
        break;
      } else {
        currentRowIndex++;
        currentColIndex = startColIndex;
      }
    } else {
      currentColIndex = getChangedColIndex(currentColIndex);
    }
  }
  if (!isEnoughSpace) {
    return {
      isEnoughSpace: false,
      emptyCols: currentColIndex - startColIndex,
    };
  }
  return {
    isEnoughSpace: true,
  };
};

const getReversedRows = (grid) => {
  const copy = JSON.parse(JSON.stringify(grid));
  return copy.map((row) => [...row].reverse());
};

const prepareInsertParams = ({
  grid,
  startRowIndex,
  colIndexAfterOrBefore,
  el,
  elRowSpan,
  elColSpan,
  shiftDirection,
}) => {
  if (shiftDirection === RIGHT) {
    return {
      grid,
      startRowIndex,
      colIndexAfter: colIndexAfterOrBefore,
      el,
      elRowSpan,
      elColSpan,
    };
  }
  const gridPrepared = grid.map((row) => [...row].reverse());
  const colIndexAfter = gridPrepared[0].length - 1 - colIndexAfterOrBefore;
  return {
    grid: gridPrepared,
    startRowIndex,
    colIndexAfter,
    el,
    elRowSpan,
    elColSpan,
  };
};

// const shiftElementsToOneCell = (grid, startRowIndex, ColIndexAfter) => {
//
// }

// row  для многострочного элемента - самая верхняя строка
// colIndexAfter возможно -1 или row.length + 1

const insertEl = (
  grid,
  startRowIndex,
  colIndexAfter,
  el,
  elRowSpan,
  elColSpan,
) => {
  const currentGrid = JSON.parse(JSON.stringify(grid));
  let currentRowIndex = startRowIndex;
  const breakRowIndex = startRowIndex + elRowSpan - 1;
  while (currentRowIndex <= breakRowIndex) {
    let currentColIndex = colIndexAfter + 1;
    const breakColIndex = currentColIndex + elColSpan - 1;
    while (currentColIndex <= breakColIndex) {
      currentGrid[currentRowIndex][currentColIndex] = { ...el };
      currentColIndex++;
    }
    currentRowIndex++;
  }
  return currentGrid;
};

// предполагается, что в направлении куда сдвигать есть пустое пространство
const shiftElements = (grid, elementIds, direction = RIGHT) => {
  const currentGrid = JSON.parse(JSON.stringify(grid));

  // TODO проверить, что элементы возвращаются в порядке слева направо
  const rowsCoords = findElementsCellsByIdSplittedByRows(elementIds, grid);
  //     const elementsCoords = elementIds.map( id => findElementCellsById(id, grid) ).reduce((acc, arr) => {
  //          acc.push(...arr)
  //          return acc
  //     },[])
  rowsCoords.forEach((row) => {
    row.forEach((_, i, row) => {
      const [rowIndex, colIndex] = row[row.length - i - 1];
      const el = currentGrid[rowIndex][colIndex];
      currentGrid[rowIndex][colIndex + 1] = el;
      currentGrid[rowIndex][colIndex] = null;
    });
  });
  //     elementsCoords.forEach(([rowIndex, colIndex]) => {
  //         const el = currentGrid[rowIndex][colIndex]
  //         currentGrid[rowIndex][colIndex] = null
  //         let nextColIndex = direction === RIGHT ? colIndex + 1 : colIndex - 1
  //         currentGrid[rowIndex][nextColIndex] = el
  //     })
  return currentGrid;
};

const insertAfter = (
  grid,
  startRowIndex,
  colIndexAfter,
  el,
  elRowSpan,
  elColSpan,
) => {
  let insertIsPossible = true;
  let currentGrid = JSON.parse(JSON.stringify(grid));
  let emptyCols;

  while (insertIsPossible) {
    const { isEnoughSpace, emptyCols: emptyColsInner } = isEnoughSpaceToInsertEl(
      currentGrid,
      startRowIndex,
      colIndexAfter,
      elRowSpan,
      elColSpan,
    );
    if (isEnoughSpace) {
      // вставляем элемент и возвращаем новую сетку
      insertIsPossible = true;
      break;
    } else {
      const firstUniqElementsArea = findFirstUniqIdsInArea(
        currentGrid,
        [startRowIndex, startRowIndex + elRowSpan - 1],
        [colIndexAfter + 1, colIndexAfter + 1 + elColSpan - 1],
      );
      if (!firstUniqElementsArea.length) {
        insertIsPossible = false;
        emptyCols = emptyColsInner;
        break;
      }
      const { shiftIsPossible, elements } = firstUniqElementsArea
        .map((id) => testShift(currentGrid, id))
        .reduce(
          (acc, { shiftIsPossible, elements }) => {
            if (shiftIsPossible) {
              acc.elements.push(...elements);
              return acc;
            }
            return {
              shiftIsPossible: false,
              elements: [],
            };
          },
          { shiftIsPossible: true, elements: [] },
        );
      if (shiftIsPossible) {
        currentGrid = shiftElements(currentGrid, elements);
        console.log(drawGrid(currentGrid));
      } else {
        insertIsPossible = false;
        emptyCols = emptyColsInner;
      }
    }
  }

  if (!insertIsPossible) {
    return { success: false, grid: currentGrid, emptyCols };
  }
  const finalGrid = insertEl(
    currentGrid,
    startRowIndex,
    colIndexAfter,
    el,
    elRowSpan,
    elColSpan,
  );
  return { success: true, grid: finalGrid };

  function testShift(grid, elementId) {
    const rightEdgeElementCells = getRightEdgeElementCells(elementId, grid);
    const rightEdgeIndex = rightEdgeElementCells[0][1];
    if (rightEdgeIndex === grid[0].length - 1) {
      return {
        shiftIsPossible: false,
        elements: [],
      };
    }
    const rightNeighbors = rightEdgeElementCells.map(
      ([row, col]) => grid[row][col + 1],
    );
    if (rightNeighbors.every((n) => n === null)) {
      return {
        shiftIsPossible: true,
        elements: [elementId],
      };
    }
    const rightNeighborsUniqSet = new Set();
    rightNeighbors.forEach((el) => {
      if (el && el.id) rightNeighborsUniqSet.add(el.id);
    });
    const uniquRightNeighborsIdArray = Array.from(rightNeighborsUniqSet);
    return uniquRightNeighborsIdArray
      .map((id) => testShift(grid, id))
      .reduce(
        (acc, { shiftIsPossible, elements }) => {
          if (shiftIsPossible) {
            acc.elements.push(...elements);
            return acc;
          }
          return {
            shiftIsPossible: false,
            elements: [],
          };
        },
        { shiftIsPossible: true, elements: [] },
      );
  }
};

const insertFinal = (
  grid,
  insertStartRow,
  insertAfterCol,
  el,
  elRowSpan,
  elColSpan,
) => {
  const {
    success,
    grid: resultGrid,
    emptyCols,
  } = insertAfter(
    grid,
    insertStartRow,
    insertAfterCol,
    el,
    elRowSpan,
    elColSpan,
  );
  consoleGridWithMessage(
    resultGrid,
    `Попытка вставить. Успех: ${success}. Свободных колонок: ${emptyCols}`,
  );
  if (!success) {
    if (emptyCols) {
      console.log('Есть свободные колонки => разбиваем сетку');
      const { left, right } = splitGrid(resultGrid, insertAfterCol);
      consoleGridWithMessage(left, 'Левая часть');
      consoleGridWithMessage(right, 'Правая часть');
      const rightGridFinal = insertEl(
        right,
        insertStartRow,
        -1,
        el,
        elRowSpan,
        emptyCols,
      );
      consoleGridWithMessage(
        rightGridFinal,
        'Вставили кусок элемента в правую сетку',
      );
      const reversedLeft = getReversedRows(left);
      consoleGridWithMessage(left, 'Левая сетка с инверсированными колонками');
      const { success: leftInsertSuccess, grid: leftInsertResultGrid } = insertAfter(
        reversedLeft,
        insertStartRow,
        -1,
        el,
        elRowSpan,
        elColSpan - emptyCols,
      );
      consoleGridWithMessage(
        leftInsertResultGrid,
        `Вставляем элемент в левую инверсированную сетку: сдвигаем элементы вправо. Успех: ${false}. Свободных колонок: ${emptyCols}`,
      );
      if (leftInsertSuccess) {
        console.log(
          'Успешно => инверсируем сетку обратно и склеиваем левую с правой',
        );
        const reversedBackFinalLeftGrid = getReversedRows(leftInsertResultGrid);
        consoleGridWithMessage(
          reversedBackFinalLeftGrid,
          'Инверсировання обратно левая сетка',
        );
        const joinedGrids = joinGrids(
          reversedBackFinalLeftGrid,
          rightGridFinal,
        );
        consoleGridWithMessage(joinedGrids, 'Склеенный результат');
        return joinedGrids;
      }
      // возвращаем сетку с вставленым вправо ужатым элементом
      const joinedGrids = joinGrids(left, rightGridFinal);
      return joinedGrids;
    }
    // справа нет сводобных колонок => пробуем вставить влево
    const { left, right } = splitGrid(resultGrid, insertAfterCol);
    const reversedLeft = getReversedRows(left);
    const {
      success: leftInsertSuccess,
      resultGrid: leftInsertResultGrid,
      emptyCols: leftInsertEmptyCols,
    } = insertAfter(
      reversedLeft,
      insertStartRow,
      -1,
      el,
      elRowSpan,
      elColSpan,
    );
    if (leftInsertSuccess) {
      const reversedBackFinalLeftGrid = getReversedRows(leftInsertResultGrid);
      const joinedGrids = joinGrids(
        reversedBackFinalLeftGrid,
        right,
      );
      return joinedGrids;
    }
    if (leftInsertEmptyCols) {
      const leftGridWithInsert = insertEl(
        leftInsertResultGrid,
        insertStartRow,
        -1,
        el,
        elRowSpan,
        leftInsertEmptyCols,
      );
      const reversedBackLeftGrid = getReversedRows(leftGridWithInsert);
      const joinedGrids = joinGrids(reversedBackLeftGrid, right);
      return joinedGrids;
    }
    return false;
  }
  return resultGrid;
};

export const mapCssGridCoordsToGridIndexes = (
  [startRowLine, endRowLine],
  [startColLine, endColLine],
) => [
  [startRowLine - 1, endRowLine - 2],
  [startColLine - 1, endColLine - 2],
];

export const getCssElementColRowSpan = ([row, col]) => [row[1] - row[0], col[1] - col[0]];

const mapGridIndexesToCssGridCoords = (
  [startRow, endRow],
  [startCol, endCol],
) => [
  [startRow + 1, endRow + 2],
  [startCol + 1, endCol + 2],
];

// element - [[startRowLine, endRowLine], [startColLine, endColLine], id]
export const getGrid = (rows, cols, cssGridElements) => {
  const gridIndexes = cssGridElements.map(([row, col, id]) => [...mapCssGridCoordsToGridIndexes(row, col), id]);
  console.log('gridIndexes', gridIndexes);
  const getCellElId = (rowIndex, colIndex) => {
    const found = gridIndexes.find(([row, col, id]) => {
      if (
        (rowIndex === row[0] || rowIndex === row[1])
        && (colIndex === col[0] || colIndex === col[1])
      ) {
        return true;
      } return false;
    });
    if (found) return found[2];
    return null;
  };
  const grid = new Array(rows).fill(null).map((_, rowIndex) => new Array(cols).fill(null).map((__, colIndex) => {
    const cellId = getCellElId(rowIndex, colIndex);
    return cellId ? { id: cellId } : null;
  }));
  return grid;
};

const getCssGridCoords = (grid) => {
  const uniqIdSet = new Set();
  grid.forEach((row) => {
    row.forEach((col) => {
      if (col && col.id) {
        uniqIdSet.add(col.id);
      }
    });
  });
  const uniqElIds = Array.from(uniqIdSet);
  const elementsCells = uniqElIds.map((id) => {
    const elementCells = findElementCellsById(id, grid);
    const {
      minRow, maxRow, minCol, maxCol,
    } = elementCells.reduce(
      (acc, [row, col]) => {
        if (acc.minRow === null || row < acc.minRow) {
          acc.minRow = row;
        }
        if (acc.maxRow === null || row > acc.maxRow) {
          acc.maxRow = row;
        }
        if (acc.minCol === null || col < acc.minCol) {
          acc.minCol = col;
        }
        if (acc.maxCol === null || col > acc.maxCol) {
          acc.maxCol = col;
        }
        return acc;
      },
      {
        minRow: null, maxRow: null, minCol: null, maxCol: null,
      },
    );
    return [
      ...mapGridIndexesToCssGridCoords([minRow, maxRow], [minCol, maxCol]),
      id,
    ];
  });
  return elementsCells;
};

const grid = [
  [null, null, { id: 3 }, null],
  [null, { id: 9 }, { id: 4 }, { id: 4 }],
  [null, { id: 2 }, { id: 4 }, { id: 4 }],
  [{ id: 1 }, null, { id: 5 }, { id: 5 }],
];

const grid1 = [
  [{ id: 1 }, null, null, null],
  [{ id: 3 }, null, null, { id: 4 }],
];

export const insertGridElement = (
  gridElements,
  gridRows,
  gridCols,
  elementInsert,
  insertStartRow,
  insertAfterCol,
) => {
  console.log(
    'insertGridElement',
    gridElements,
    gridRows,
    gridCols,
    elementInsert,
    insertStartRow,
    insertAfterCol,
  );
  const grid = getGrid(gridRows, gridCols, gridElements);
  console.log(grid);
  console.log(drawGrid(grid));
  const [elRowSpan, elColSpan] = getCssElementColRowSpan(elementInsert);
  console.log('el span', elRowSpan, elColSpan);
  const id = elementInsert[2];
  const finalGrid = insertFinal(
    grid,
    insertStartRow,
    insertAfterCol,
    { id },
    elRowSpan,
    elColSpan,
  );
  console.log(drawGrid(finalGrid));
  const insertResult = getCssGridCoords(finalGrid);
  console.log('insertResult', insertResult);
  return insertResult;
};

export const getDropParamsFromGridIndexes = ([[rowStart, rowEnd], [colStart, colEnd]]) => ({
  insertStartRow: rowStart,
  insertAfterCol: colStart - 1,
});

const test = (grid) => {
  console.log(drawGrid(grid));
  const insertStartRow = 0;
  const insertAfterCol = 1;
  const el = { id: 6 };
  const elRowSpan = 3;
  const elColSpan = 2;
  const finalGrid = insertFinal(
    grid,
    insertStartRow,
    insertAfterCol,
    el,
    elRowSpan,
    elColSpan,
  );
  console.log(drawGrid(finalGrid));
};

const testCssToGrid = () => {
  const elementInsert = [[1, 3], [1, 2], 7];
  const gridElements = JSON.parse(
    '[[[1,2],[3,4],3],[[2,3],[2,3],9],[[2,4],[3,5],4],[[3,4],[2,3],2],[[4,5],[1,2],1],[[4,5],[3,5],5]]',
  );
  insertGridElement(gridElements, 4, 4, elementInsert, 0, -1);
};
