import { Ref, ref } from 'vue';
import { IDialogComponent, useDialog } from '@/hooks/useDialog';
import { IToastLevel, useToast } from '@/hooks/useToast';
import { SignalType, useSignal } from '@/hooks/useSignal';
import { useUnsubs } from '@/hooks/useUnsubs';
import { ActiveFormFieldType } from '@/hooks/useActiveForm';
import { apiCommands as reportingApiCommands } from '@/service/api/reporting/api';
import { ApiCommand as ReportingApiCommand } from '@/service/api/reporting/apiCommand';
import { useProtectedDefaultCompany } from '@/hooks/useProtectedDefaultCompany';
import { ReportingDashboard, ReportingDashboardMode } from '@/service/api/reporting/dasboard';
import { useCompanies } from '@/hooks/useCompanies';
import { useUser } from '@/hooks/useUser';

export const useCreateDashboard = (groups: Ref<ReportingDashboard[]>) => {
  const GRID_CONFIG_DEFAULT = '{"rows":"4","cols":"5","rowSizes":"150px 300px 300px 300px"}';

  const { companyId } = useProtectedDefaultCompany();
  const { companies } = useCompanies();
  const { isStaff } = useUser();

  const { showToast } = useToast();

  const {
    showDialog,
    confirmDialog,
  } = useDialog();

  const { sub, unsub } = useUnsubs();
  const { subscribeToSignal, dispatchSignal } = useSignal();

  const isCreating = ref(false);

  const result = ref<ReportingDashboard>();

  const signal = 'group_created';

  const createDashboard = async () => new Promise<ReportingDashboard|undefined>(async (resolve) => {
    isCreating.value = true;
    const close = await showDialog({
      component: IDialogComponent.model,
      addInRoute: false,
      payload: {
        record: {
          name: '',
          mode: ReportingDashboardMode.user_and_company,
          companies: [],
          use_not_for_current_company: false,
        },
        signal,
        isEditable: true,
        fields: [
          {
            key: 'name',
            field: 'name',
            type: ActiveFormFieldType.input,
            options: {
              label: 'Введите имя дашборда',
            },
          },
          {
            key: 'mode',
            field: 'mode',
            type: ActiveFormFieldType.select,
            options: {
              label: 'Доступ',
              allowEmpty: false,
              options: [
                {
                  label: 'Текущий пользователь (все компании)',
                  value: ReportingDashboardMode.user,
                },
                {
                  label: 'Все пользователи (текущая компания)',
                  value: ReportingDashboardMode.company,
                },
                {
                  label: 'Текущий пользователь (текущая компания)',
                  value: ReportingDashboardMode.user_and_company,
                },
              ],
            },
          },
          {
            key: 'dashboard',
            field: 'dashboard',
            type: ActiveFormFieldType.select,
            options: {
              label: 'Скопировать плитки из существующего',
              displayField: 'name',
              valueField: 'id',
              options: groups.value,
            },
            onUpdateModelValue: (model, value) => {
              if (!value) {
                model.use_not_for_current_company = false;
                model.companies = [];
                model.all_companies = false;
              }
            },
          },
          {
            key: 'use_not_for_current_company',
            field: 'use_not_for_current_company',
            type: ActiveFormFieldType.radio,
            options: {
              label: 'Расчет дашборда',
              options: [
                {
                  label: 'Для текущей компании',
                  value: false,
                },
                {
                  label: 'Для разных компаний',
                  value: true,
                },
              ],
            },
          },
          {
            key: 'companies',
            field: 'companies',
            type: ActiveFormFieldType.select,
            checkHidden: (model) => !model.use_not_for_current_company,
            defaultValue: [],
            options: {
              label: 'Выбрать компании',
              options: companies.value.map(
                (c) => ({ value: c.id, label: c.name_short }),
              ),
            },
          },
          {
            key: 'all_companies',
            field: 'all_companies',
            type: ActiveFormFieldType.checkbox,
            checkHidden: (model) => !model.use_not_for_current_company,
            options: {
              label: 'Все компании',
            },
            onUpdateModelValue: (model, value) => {
              if (value) {
                model.companies = [];
              }
            },
          },
        ],
      },
    });
    let unsubClosedDialog: () => void;
    const unsubSignal = subscribeToSignal(
      SignalType.model,
      async (payload: { id: string; model: { name: string; dashboard: string; mode: ReportingDashboardMode; use_not_for_current_company: boolean; companies: number[]; all_companies: boolean } }) => {
        if (payload.id === signal) {
          const isCopy = payload.model.dashboard;
          const {
            status,
            response,
          } = await (
            isCopy
              ? reportingApiCommands[
                ReportingApiCommand.dashboardCopy
              ].request({
                dashboard_id: payload.model.dashboard,
                company_id: companyId.value,
                name: payload.model.name,
                mode: payload.model.mode,
                save_parent_id: false,
                ...(payload.model.use_not_for_current_company
                  ? (
                    payload.model.all_companies
                      ? { all_companies: true }
                      : { companies: payload.model.companies }
                  ) : {}
                ),
              })
              : reportingApiCommands[
                ReportingApiCommand.dashboardPost
              ].request({
                company_id: companyId.value,
                name: payload.model.name,
                mode: payload.model.mode,
                config: {
                  rows: 7,
                  cols: 5,
                  rowSizes: '80px 300px 300px 300px',
                  colSizes: '1fr 1fr 1fr 1fr 1fr',
                },
                ...(payload.model.use_not_for_current_company
                  ? (
                    payload.model.all_companies
                      ? { all_companies: true }
                      : { companies: payload.model.companies }
                  ) : {}
                ),
              })
          );
          if (!status) {
            dispatchSignal(
              SignalType.modelErrors,
              ({
                id: payload.id,
                errors: response,
              }),
            );
            showToast({
              label: 'Ошибка создания дашборда',
              params: {
                pureLabel: '{label}',
              },
              level: IToastLevel.danger,
            });
          } else {
            result.value = response;
            showToast({
              label: 'Дашборд успешно создан',
              params: {
                pureLabel: '{label}',
              },
              level: IToastLevel.success,
            });
            isCreating.value = false;
            unsub(unsubSignal);
            unsub(close);
            unsub(unsubClosedDialog);
            resolve(result.value);
          }
        }
      },
    );
    unsubClosedDialog = subscribeToSignal(
      SignalType.dialogClosed,
      ({ component, payload }: any) => {
        if (component === IDialogComponent.model
            && payload.signal === signal
        ) {
          isCreating.value = false;
          unsub(unsubSignal);
          unsub(unsubClosedDialog);
          unsub(close);
          resolve(result.value);
        }
      },
    );
    sub(unsubClosedDialog);
    sub(unsubSignal);
    sub(close);
  });

  return {
    createDashboard,
  };
};
